/*-----------------------------------*\
    Navigation
\*-----------------------------------*/

.aa-navigation-container {
    display: flex;
    align-items: center;

    @include bp(lg) {
        margin-left: auto;
    }
}

.aa-navigation {
    ul, li { // Reset
        list-style: none;
        padding: 0;
        margin: 0;
    }

    // ul {
    //     display: flex;
    //     align-items: center;
    // }

    ul li {}

    ul li a {
        display: block;

        text-decoration: none;
    }
}

.aa-navigation--primary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @include bp(md) {
        flex-direction: row;
    }
}

.aa-navigation--primary .hs-menu-wrapper > ul {
    display: flex;
    justify-content: flex-end;
    height: inherit;

    list-style: none;
}

.aa-navigation--primary .hs-menu-wrapper > ul > li {
    height: inherit;
}

.aa-navigation--primary .hs-menu-wrapper > ul > li > a {
    display: flex;
    align-items: center;
    height: inherit;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: $weight-700;

    color: RGB(var(--complementary));

    @include bp(lg) {
        margin-right: 25px;
        border-bottom: 2px solid transparent;

        &:hover {
            border-bottom: 2px solid RGB(var(--complementary));
        }
    }

    @include bp(hg) {
        margin-right: 30px;
    }
}

.aa-navigation--primary .hs-menu-depth-1.hs-item-has-children {
    position: relative;
}

.aa-navigation--primary .hs-menu-depth-1.hs-item-has-children > .hs-menu-children-wrapper {
    @include bp(lg) {
        display: none;
        flex-wrap: wrap;
        width: auto;
        position: absolute;
        top: calc(100% + 20px);

        background: RGB(var(--white));
        box-shadow: $box-shadow;

        &:before {
            display: block;
            position: absolute;
            content: '';
            top: -20px;
            left: 0;
            right: 0;
            height: 20px;
        }
    }

    @include bp(xl) {
        flex-wrap: nowrap;
        width: auto;
    }
    li {
        padding-left: 0;
    }
    a {
        flex-direction: column;

        @include bp(lg){
            width: 250px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
        &:hover {
            background-color: RGBA(var(--primary), 0.1);
            border-bottom: 0;
        }
        p:first-of-type {
            display: flex;
            margin-bottom: 5px;
            border-bottom: 1px solid RGB(var(--light));

        }
        p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 42px;
        }
        &:after {
            content:'';
            background-image: none;
        }
    }
}

.aa-navigation--primary .hs-menu-depth-1:hover > .hs-menu-children-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    @include bp(lg) {
        display: flex;
        flex-direction: column;
    }
}







html {
	transition: transform $speed ease;
}
html.js-nav-open {
	// height: 100%;

	// overflow-y: hidden;
	// transform: translateX(-80%);
}
body.js-nav-open {
	overflow-y: hidden;
}

.aa-navigation--mobile {
    width: 100%;
    min-height: 100vh;
	position: fixed;
	bottom: 100%;
    right: 0;

    font-size: 16px;
    line-height: 24px;

    background-color: RGB(var(--white));
	overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    z-index: 998;

	@include bp(md) {
		padding-top: 50px;
    }
    .header-button {
        margin-left: 20px;
        margin-top: 30px;
    }
}
.aa-navigation--mobile ul {
    flex-direction: column;
}
.aa-navigation--mobile ul li {
    padding-top: 30px;
    padding-bottom: 30px;
}

.js-nav-open .aa-navigation--mobile {
    top: 80px;
    bottom: 0;
}

.aa-navigation--mobile ul {
	// Reset
	padding: 0;
	margin: 0;
	list-style: none;
}
.aa-navigation--mobile ul li {
	// Reset
    padding: 0;
    margin: 0;
}
.aa-navigation--mobile ul li a {
    display: block;
    height: 60px;

    color: RGB(var(--black));
	text-decoration: none;
}

.aa-navigation--mobile .hs-menu-depth-1 {
    border-bottom: 1px solid #eee;

    > a {
        padding: 13px 20px;

        font-size: 16px;
        line-height: 30px;
        font-weight: $weight-700;
    }

    > ul {
        display: none;
        background: RGB(var(--primary));
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &.is-active > ul {
        display: block;
    }
}


.aa-navigation--mobile .hs-menu-depth-2 {
    a {
        padding: 5px 20px;
        color: RGB(var(--white));
    }

    > ul {
        display: none;
        padding: 5px 20px;

        color: RGB(var(--white));
        background-color: RGB(var(--primary));
    }

    &.is-active > ul {
        display: block;
    }
}

.aa-navigation--mobile .hs-item-has-children {
    position: relative;

    > a {
        display: flex;
        align-items: center;
    }
}

.aa-menu-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;

    transition: all $speed $animation;
}

.hs-item-has-children.is-active .aa-menu-arrow svg {
    transform: rotate(90deg);
}