/*-----------------------------------*\
	Buttons
\*-----------------------------------*/

.aa-button {
    position: relative;
	display: inline-flex;
	align-self: flex-start;

	margin-bottom: 0;
	padding: 20px 40px;

	border-radius: 2px;

    text-decoration: none;
    font-weight: $weight-700;
    font-size: 16px;
    line-height: 19px;

	cursor: pointer;
	transition: all $speed $animation;
}

/*
 * Full buttons
 */
.aa-button--primary,
.aa-button--secondary,
.aa-button--complementary,
.aa-button--light,
.aa-button--dark,
.aa-button--white
 {
    border-width: 2px;
    border-style: solid;
}
.aa-button--primary {
    background-color: RGB(var(--primary));
    border-color: RGB(var(--primary));

    &:hover {
        background-color: #154FAD;
        border-color: #154FAD;
    }
}
.aa-button--secondary {
    background-color: #E0EBF6;
    border-color: #E0EBF6;

    &:hover {
        background-color: #001F44;
        border-color: #001F44;
    }
}
.aa-button--complementary {
    background-color: RGB(var(--complementary));
    border-color: RGB(var(--complementary));

    &:hover {
        background-color: darken($complementary, 10%);
        border-color: darken($complementary, 10%);
    }
}
.aa-button--light {
    background-color: RGB(var(--light));
    border-color: RGB(var(--light));

    &:hover {
        // background-color: darken($light, 10%);
        // border-color: darken($light, 10%);
    }
}
.aa-button--dark {
    background-color: RGB(var(--dark));
    border-color: RGB(var(--dark));

    &:hover {
        // background-color: darken($dark, 10%);
        // border-color: darken($dark, 10%);
    }
}
.aa-button--white {
    background-color: RGB(var(--white));
    border-color: RGB(var(--white));

    &:hover {
        // background-color: darken($white, 10%);
        // border-color: darken($white, 10%);
    }
}

/*
 * Outlined buttons
 */

.aa-button--outline {
    background-color: transparent;
}

.aa-button-border--primary {
    border: 2px solid RGB(var(--primary));

    &:hover {
        background-color: RGB(var(--primary));
        color: RGB(var(--white));
    }
}
.aa-button-border--secondary {
    border: 2px solid RGB(var(--secondary));

    &:hover {
        // border-color: darken($secondary, 10%);
    }
}
.aa-button-border--complementary {
    border: 2px solid RGB(var(--complementary));

    &:hover {
        // border-color: darken($complementary, 10%);
    }
}
.aa-button-border--light {
    border: 2px solid RGB(var(--light));

    &:hover {
        // border-color: darken($light, 10%);
    }
}
.aa-button-border--dark {
    border: 2px solid RGB(var(--dark));

    &:hover {
        // border-color: darken($dark, 10%);
    }
}
.aa-button-border--white {
    border: 2px solid RGB(var(--white));

    &:hover {
        // border-color: darken($white, 10%);
    }
}

/*
 * Button Text Colors
 */
.aa-button-color--light {
    color: RGB(var(--light));

    &:hover {
        color: RGB(var(--light));
    }
}
.aa-button-color--dark {
    color: RGB(var(--dark));

    &:hover {
        color: RGB(var(--dark));
    }
}
.aa-button-color--white {
    color: RGB(var(--white));

    &:hover {
        color: RGB(var(--white));
    }
}

.aa-button-border--primary.aa-button-color--dark,
.aa-button--primary.aa-button-color--dark,
.aa-button--secondary.aa-button-color--dark {
    color: RGB(var(--black));

    &:hover {
        color: RGB(var(--white));
    }
}

.aa-button-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include bp(sm) {
        flex-direction: row;
    }

    .aa-button + .aa-button {
        margin-top: 20px;

        @include bp(sm) {
            margin-top: 0;
            margin-left: 20px;
        }
    }
}

// Load more button

.aa-button-hidden {
    display: none;
}