/*-----------------------------------*\
	Hamburger
\*-----------------------------------*/

.aa-hamburger {
	cursor: pointer;
	z-index: 200;
	width: 25px;
	height: 20px;
	display: flex;
	align-items: center;

	span,
	span:before,
	span:after {
		display: block;

		background-color: RGB(var(--complementary));

		transition-property: all;
		transition-duration: .1s;
	}

	span {
		margin: 0 auto;
		position: relative;
	}
	span:before,
	span:after {
		position: absolute;
		content: '';
	}

	span,
	span:before,
	span:after {
		width: 25px;
		height: 2px;
	}
	span:before {
		transform: translateY(-7px);
	}
	span:after {
		transform: translateY(7px);
	}
}

.js-nav-open .aa-hamburger {
	transition: transform $speed ease;

	span {
		background-color: transparent !important;

		&:before,
		&:after {
			transform: translateY(0);
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
}
