/*-----------------------------------*\
	Variables
\*-----------------------------------*/

/*
 * Assets path
 */
 $assets-path: '/src/assets';

/*
 * Font weights
 * Defined by number since weight name can vary depending on font-family.
 */
$weight-100: 100 !default;
$weight-200: 200 !default;
$weight-300: 300 !default;
$weight-400: 400 !default; // Equivalent of regular
$weight-500: 500 !default;
$weight-600: 600 !default;
$weight-700: 700 !default; // Equivalent of bold
$weight-800: 800 !default;
$weight-900: 900 !default;
/*
 * Font-families
 */
 $font-header: 'Roboto', sans-serif !default;
 $font-body: 'Roboto', sans-serif !default;
 $font-quote: 'Roboto', sans-serif !default;

// Site widths
$full-site-width: 1440px !default;
$max-site-width: 100% !default;

// Sizes
$padding: 20px;
$border-radius: 4px;

// UX
$speed: 250ms !default;
$animation: ease-in-out !default;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1) !default;

// Selector to target IE11 specifically.
// Usage: #{$ie11} .my-selector { color: blue; }
$ie11: '_:-ms-fullscreen, :root';

//extra colors

$medium-grey: #9AA1A9;
$fuschia: #BC2899;
$darkblue: #0062A7;
$complementary: #BC2899;