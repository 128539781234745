/*-----------------------------------*\
	Elements
\*-----------------------------------*/

/*
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
 html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
	background-color: #F4F7F8;
}

main {}

a {
	font-size: 18px;
	line-height: 29px;
	font-weight: $weight-400;
	text-decoration: underline;
	text-decoration-skip-ink: auto;

	color: RGB(var(--black));

	transition: all $speed $animation;
}

a:visited {
}

a:hover,
a:focus,
a:active {
	color: inherit;
}

a:focus {
	outline: none;
}

a:hover,
a:active {
	outline: 0;
	text-decoration: none;
}

img, video, iframe {
	max-width: 100%;
}