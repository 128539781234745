/* -------------------------------- *\
    Components / Modal
\* -------------------------------- */

.aa-modal {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 30px;

    background-color: RGB(var(--black), 0.7);
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: opacity $speed $animation;

    img, video {
        display: block;
        max-width: 100%;
    }
}

.aa-modal--open {
    visibility: visible;
    opacity: 1;
}

.aa-modal__inner {
    width: 100%;
    max-width: calc(#{$full-site-width} / 12 * 8);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 50px;
    top: 400px;

    background-color: RGB(var(--white));
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);

    @include bp(lg){
        top: 150px;
    }
}
.aa-modal--video {
    .aa-modal__inner {
        top: 0;
    }
}
.aa-modal__body {
}

.aa-modal__close  {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    right: 20px;

    cursor: pointer;
    z-index: 999999;
    transform: translateX(50%) translateY(-50%);
    transition: all $speed $animation;

    &:before, &:after {
        content: '';
        flex: 0 0 auto;
        width: 2px;
        height: 23px;
        background-color: RGB(var(--primary));
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
    }

    &:before {
        transform: rotate(-45deg);
        margin-right: -2px;
    }

    &:after {
        transform: rotate(45deg);
        margin-left: 0;
    }
}

.aa-modal--fullscreen {
    img, video {
        display: block;
        width: 100%;
        max-width: $full-site-width;
        margin-left: auto;
        margin-right: auto;
    }

    .aa-modal__inner {
        position: static;
        max-width: none;
        padding: 0;

        background-color: transparent;
    }

    .aa-modal__close {
        top: 20px;
        right: 20px;

        background: none;
        border-radius: 0px;
        transform: none;

        &:before, &:after {
            display: block;
            width: 4px;
            height: 40px;
        }

        &:before {
            margin-right: -2px;
        }

        &:after {
            margin-left: -2px;
        }
    }
}

body.aa-modal-active {
    overflow-y: hidden;

    header.header {
        z-index: -1;
    }
}
.modal-form {
    h3 {
        color: RGB(var(--black));
    }
    .aa-modal__inner {
        max-width: calc(#{$full-site-width} / 12 * 5);
    }
    .aa-modal__body {
        max-width: calc(#{$full-site-width} / 12 * 4);
    }
}