/* -------------------------------- *\
    Forms
\* -------------------------------- */

/*
 * Form-specific variables
 */

// Set error colors to correspond to content color
.aa-content-color--light .hs-form {
    --form-error-color: RGB(var(--light));
}
.aa-content-color--dark .hs-form {
    --form-error-color: RGB(var(--dark));
}

// Padding left/right on form fields. Also used to position help texts and error messages.
$form-input-x-padding: 15px;
$form-input-x-padding-lg: 20px;

// Horizontal spacing between form fields in multi-column layouts
$form-field-gutter: 10px;

.hs-form {
    font-size: 16px; // Per default, most elements within the form inherit this font size.
    line-height: 22px; // Per default, most elements within the form inherit this line height.

    text-align: left; // Always align form fields to the left, independent on module alignment.
}


/*
 * Layout & containers
 */

// Field container
.hs-form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include bp(md) {
        margin-bottom: 25px;
    }
}

// Specific field type containers
.hs-fieldtype-text {}
.hs-fieldtype-select {}
.hs-fieldtype-booleancheckbox {}
.hs-fieldtype-checkbox {}
.hs-fieldtype-radio {}
.hs-fieldtype-date {}
.hs-fieldtype-file {}
.hs-fieldtype-textarea {}


/*
 * Input fields
 */

// All input fields
.hs-input {
    width: 100%;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: $form-input-x-padding;
    padding-right: $form-input-x-padding;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #ffffff;

    text-decoration: none;

    cursor: pointer;
    box-shadow: none;

    @include bp(lg) {
        height: 60px;
        padding-left: $form-input-x-padding-lg;
        padding-right: $form-input-x-padding-lg;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: inherit;
        opacity: 0.5;
    }
}

// Checbox/radio inputs
.hs-input[type="checkbox"],
.hs-input[type="radio"] {
    width: auto;
    height: auto;
    margin-right: 8px;
    border: 0;

    background-color: transparent;
}

// Checbox/radio input labels
.hs-form-field .inputs-list label span {
    user-select: none;
}

// Single checkbox inputs
.hs-fieldtype-booleancheckbox {
    .hs-input {}
}

// Multiple checkboxes/radios
.hs-form-field .multi-container {
    li {
        margin: 0; // Reset

        &:not(:last-of-type) {
            margin-bottom: 5px;
        }
    }
}

// Select (dropdown) inputs
.hs-fieldtype-select .input {
    position: relative; // Make it possible to align the icon
}

.hs-fieldtype-select .input svg {
    position: absolute;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(100% - (#{$form-input-x-padding} * 2));
    transform: translateX(-50%);

    pointer-events: none;

    @include bp(lg) {
        left: calc(100% - (#{$form-input-x-padding-lg} * 2));
    }
}
.hs-fieldtype-select select.hs-input svg path {
    fill: RGB(var(--black));
}

.hs-fieldtype-select select.hs-input:hover ~ svg path {
    fill: RGB(var(--secondary));
}

select.hs-input {
    appearance: none;

    &:focus:hover {
        border-color: RGB(var(--complementary));
    }

    option { // Dropdown items
        color: RGB(var(--black));

        background-color: RGB(var(--white));
    }
}

.hs-fieldtype-textarea textarea {
    width: 100%;
    height: 100px;
    padding: $form-input-x-padding;

    @include bp(lg) {
        padding: $form-input-x-padding-lg;
    }
}

// File inputs
.hs-input[type="file"] {
    height: auto;
    padding: 0;

    background-color: transparent;
    border-color: transparent;
}

// Date inputs
.hs-fieldtype-date .hs-dateinput {
    position: relative; // Make it possible to align the icon
}

.hs-fieldtype-date .hs-dateinput svg {
    position: absolute;
    height: 24px;
    top: calc(50% - 12px);
    left: calc(100% - (#{$form-input-x-padding} * 2));
    transform: translateX(-50%);

    pointer-events: none;

    @include bp(lg) {
        left: calc(100% - (#{$form-input-x-padding-lg} * 2));
    }
}
.hs-fieldtype-date .hs-dateinput svg path {
    fill: RGB(var(--black));
}

.hs-fieldtype-date .hs-dateinput:hover svg path {
    fill: RGB(var(--secondary));
}

.hs-fieldtype-date .hs-input {

    &:focus:hover {
        border-color: RGB(var(--complementary));
    }
}

// Date picker dropdown
.hs-fieldtype-date .hs-datepicker {
    .fn-date-picker .pika-button {
        box-shadow: none !important; // !important declaration to override HS styling.
        border-radius: 0 !important; // !important declaration to override HS styling.

        &:hover {
            background-color: RGB(var(--primary)) !important; // !important declaration to override HS styling
            opacity: 0.7 !important;
        }
    }

    .fn-date-picker .is-selected .pika-button {
        background-color: RGB(var(--complementary));
        color: RGB(var(--dark));
    }

    .fn-date-picker .is-today .pika-button {
        color: RGB(var(--dark));
    }
}


/*
 * Submit button
 */
.hs-submit {
    margin-top: 20px;

    input[type="submit"] {
        @extend .aa-button;
        @extend .aa-button--primary;

        display: block;
        width: 100%;
    }
}
.aa-button, .hs-submit input[type=submit] {

}

// And the special stuff that makes it inherit from the button styles, based on classes given from fields
.aa-form-button--full {
    &.aa-form-button--primary {
        input[type="submit"] {
            @extend .aa-button--primary;
        }
    }
    &.aa-form-button--secondary {
        input[type="submit"] {
            @extend .aa-button--secondary;
        }
    }
    &.aa-form-button--complementary {
        input[type="submit"] {
            @extend .aa-button--complementary;
        }
    }
    &.aa-form-button--light {
        input[type="submit"] {
            @extend .aa-button--light;
        }
    }
    &.aa-form-button--dark {
        input[type="submit"] {
            @extend .aa-button--dark;
        }
    }
    &.aa-form-button--white {
        input[type="submit"] {
            @extend .aa-button--white;

            color: RGB(var(--primary));

            &:hover {
                color: RGB(var(--primary),0.8);
            }
        }
    }
}

.aa-form-button--outline  {
    input[type="submit"] {
        @extend .aa-button--outline;
    }

    &.aa-form-button-border--primary {
        input[type="submit"] {
            @extend .aa-button-border--primary;
        }
    }
    &.aa-form-button-border--secondary {
        input[type="submit"] {
            @extend .aa-button-border--secondary;
        }
    }
    &.aa-form-button-border--complementary {
        input[type="submit"] {
            @extend .aa-button-border--complementary;
        }
    }
    &.aa-form-button-border--light {
        input[type="submit"] {
            @extend .aa-button-border--light;
        }
    }
    &.aa-form-button-border--dark {
        input[type="submit"] {
            @extend .aa-button-border--dark;
        }
    }
    &.aa-form-button-border--white {
        input[type="submit"] {
            @extend .aa-button-border--white;
        }
    }
}

.aa-form-button-color--light {
    input[type="submit"] {
        @extend .aa-button-color--light;
    }
}
.aa-form-button-color--dark {
    input[type="submit"] {
        @extend .aa-button-color--dark;
    }
}
.aa-form-button-color--white {
    input[type="submit"] {
        @extend .aa-button-color--white;
    }
}


/*
 * Text and other form elements
 */
.hs-form ul,
.hs-form ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
    }
}

// Form field main label
.hs-form-field > label {
    display: block;
    margin-bottom: 5px;

    font-weight: $weight-700;

    &:empty {
        display: none;
    }
}

// Form field help text
.hs-form legend {
    order: 1; // Positions it below the input field
    display: block;
    margin-top: 5px;
    margin-left: $form-input-x-padding;

    font-size: 0.8em;
    line-height: 0.8em;

    @include bp(lg) {
        margin-top: 10px;
        margin-left: $form-input-x-padding-lg;
    }
}

// Rich text areas
.hs-richtext {
    p {
        font-size: inherit;
        line-height: inherit;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    + .hs-form-field {
        margin-top: 10px;
    }
}

// Error messages
.hs-input.error {
    border-color: RGB(var(--form-error-color));
}

ul.hs-error-msgs {
    order: 10; // Position it last in the field container
    margin-top: 5px;
    margin-left: $form-input-x-padding;

    @include bp(lg) {
        margin-left: $form-input-x-padding-lg;
    }

    li {
        list-style: none;
    }

    label {
        color: RGB(var(--form-error-color));
        font-size: 0.8em;
        line-height: 0.8em;
    }
}

// The final error message just above the submit button (shows if you try to submit the form but there's an error)
.hs_error_rollup {
    margin-top: 15px;
    padding: 15px;
    border: 1px solid RGB(var(--form-error-color));

    .hs-error-msgs {
        margin: 0;
    }
}

// Form title that renders if the "form_title" parameter is set to true
.hs-form .form-title {
    @extend .h3;

    hyphens: manual;
}


/*
 * Custom GDPR fields
 */
.hs_gdpr_consent_marketing,
.hs_gdpr_consent_data {
    .hs-form-booleancheckbox label {
        font-weight: $weight-700;
    }
}


/*
 * Reset/override unwanted HubSpot styling.
 * Note: We use a lot of CSS specificity here on purpose in order to beat HubSpot's specificity.
 */
form.hs-form fieldset {
    max-width: none;
}

form.hs-form fieldset.form-columns-1,
form.hs-form fieldset.form-columns-2,
form.hs-form fieldset.form-columns-3 { // Override HubSpot styling
    .hs-form-field {
        width: 100%;
    }

    .input {
        margin-right: 0;
    }
}

form.hs-form fieldset.form-columns-1 { // Override HubSpot styling
    .hs-input {
        width: 100%;
    }

    input[type="checkbox"].hs-input,
    input[type="radio"].hs-input {
        width: auto;
    }
}

form.hs-form fieldset.form-columns-2 { // Override HubSpot styling
    .hs-form-field {
        @include bp(md) {
            width: calc(50% - #{$form-field-gutter} / 2);
        }

        &:not(:first-of-type) {
            @include bp(md) {
                margin-left: $form-field-gutter;
            }
        }
    }
}

form.hs-form fieldset.form-columns-3 {
    .hs-form-field {
        @include bp(md) {
            width: calc(100% / 3 - 2 * #{$form-field-gutter} / 3);
        }

        &:not(:first-of-type) {
            @include bp(md) {
                margin-left: $form-field-gutter;
            }
        }
    }
}

@media (max-width: 400px), (max-device-width: 480px) and (min-device-width: 320px) { // Override HubSpot styling
    form.hs-form fieldset.form-columns-2,
    form.hs-form fieldset.form-columns-3 {
        .hs-input {
            width: 100% !important;
        }

        input[type="checkbox"],
        input[type="radio"] {
            width: auto !important;
        }
    }
}

//hero form

.aa-hero-form.aa-content-color--light {
    color: RGB(var(--white));
}
.aa-hero-form.aa-content-color--dark {
    color: RGB(var(--dark));
}