/*-----------------------------------*\
	Utilities
\*-----------------------------------*/

.aa-bg-image {
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
}


hr {
	margin-top: 0;
	margin-bottom: 30px;

	border: 0;
	border-top: 4px solid RGB(var(--light));

	@include bp(lg) {
		margin-bottom: 40px;
	}
}

img {
	display: block;
	max-width: 100%;
}

ul, ol {
	margin-top: 0;
}

/*
 * Background Colorings and more
 */

.aa-bg-primary {
	background-color: RGB(var(--primary));
}
.aa-bg-secondary {
	background-color: RGB(var(--secondary));
}
.aa-bg-complementary {
	background-color: RGB(var(--complementary));
}
.aa-bg-light {
	background-color: RGB(var(--light), 0.2);
}
.aa-bg-dark {
	background-color: RGB(var(--dark));
}
.aa-bg-green-gradient {
	background: linear-gradient(34.91deg, rgba(218, 244, 69, 0.2) -40.51%, rgba(73, 203, 177, 0.2) 81.79%);
}
.aa-bg-pink-gradient {
	background: linear-gradient(34.91deg, rgba(188, 40, 153, 0.1) -40.51%, rgba(229, 11, 71, 0.1) 81.79%);
}
.aa-bg-green-opaque {
	background: rgba(#49CBB1, 0.2);
}

.aa-bg-overlay {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		z-index: 5;
	}

	> .aa-inner {
		position: relative;
		z-index: 10;
	}
}

.aa-bg-overlay--primary:before {
	background-color: RGB(var(--primary));
	opacity: 0.7;
}
.aa-bg-overlay--secondary:before {
	background-color: RGB(var(--secondary));
	opacity: 0.7;
}
.aa-bg-overlay--complementary:before {
	background-color: RGB(var(--complementary));
	opacity: 0.7;
}
.aa-bg-overlay--light:before {
	background-color: RGB(var(--light));
	opacity: 0.7;
}
.aa-bg-overlay--dark:before {
	background-color: RGB(var(--dark));
	opacity: 0.7;
}
.aa-bg-overlay--white:before {
	background-color: RGB(var(--white));
	opacity: 0.7;
}

.aa-content-color {

}
.aa-content-color--light,
a.aa-content-color--light {
	color: RGB(var(--text-light));
}
.aa-content-color--dark,
a.aa-content-color--dark {
	color: RGB(var(--text-dark));
}
.aa-content-color--white,
a.aa-content-color--white {
	color: RGB(var(--white));
}

.aa-bg-video {
	position: relative;

	> .aa-inner {
		position: relative;
		z-index: 10;
	}
}
.aa-bg-video video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	width: 100%;
	height: 100%;
    object-fit: cover;
}

/*
 * New THEME specific stuff
 */


 /*
 * The container of a module
 */
.aa-module {
	display: flex;
}
/*
 * Sets the width of the module
 */
.aa-module-width--page,
.aa-module-width--full {
	width: 100%;
}
.aa-module-width--page {
	@include bp(lg) {
		max-width: $full-site-width;
		margin-left: auto;
		margin-right: auto;
	}
}
/*
 * Sets the height of the module
 */
.aa-module-height--small,
.aa-module-height--normal,
.aa-module-height--full {
	display: flex;
	align-items: center;
}
.aa-module-height--small {
	min-height: 400px;
}
.aa-module-height--normal {
	min-height: 800px;
}
.aa-module-height--full {
	height: 100vh;
}

/*
 * Sets the spacing on the module
 */
.aa-module-spacing--small {
	@extend %eiffel-top;
	@extend %eiffel-bottom;
}
.aa-module-spacing--medium {
	@extend %empire-top;
	@extend %empire-bottom;
}
.aa-module-spacing--large {
	@extend %burj-top;
	@extend %burj-bottom;
}

.aa-module-spacing-bottom--small {
	@extend %eiffel-bottom;
}
.aa-module-spacing-bottom--medium {
	@extend %empire-bottom;
}
.aa-module-spacing-bottom--large {
	@extend %burj-bottom;
}

.aa-module-spacing-top--small {
	@extend %eiffel-top;
}
.aa-module-spacing-top--medium {
	@extend %empire-top;
}
.aa-module-spacing-top--large {
	@extend %burj-top;
}

/*
 * The container of a modules content
 */
.aa-content {
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;

	@include bp(hg) {
		padding-left: 0;
		padding-right: 0;
	}
}

/*
 * Controls the position of the content within the module
 */
.aa-content-position--left {
	justify-content: flex-start;
}
.aa-content-position--center {
	justify-content: center;
}

/*
 * Controls the flow of the content within the module
 */
.aa-content-flow--row,
.aa-content-flow--column {
	display: flex;
}
.aa-content-flow--row {
	flex-direction: row;
}
.aa-content-flow--column {
	flex-direction: column;
}

/*
 * Controls the alignment of the content within the module
 */
.aa-content-alignment--left {
	text-align: left;
}
.aa-content-alignment--center {
	text-align: center;
}



.aa-inner {
	display: flex;
	width: 100%;

	@include bp(lg) {
		max-width: calc(#{$full-site-width} / 12 * 10);
		margin-left: auto;
		margin-right: auto;
	}
}
/*
 * Sets the width of the content in a module
 */
.aa-content-width--narrow,
.aa-content-width--normal,
.aa-content-width--wide {
	width: 100%;
}
.aa-content-width--narrow {
	@include bp(lg) {
		max-width: calc(#{$full-site-width} / 12 * 4);
	}
}
.aa-content-width--normal {
	@include bp(lg) {
		max-width: calc(#{$full-site-width} / 12 * 6);
	}
}
.aa-content-width--wide {
	@include bp(lg) {
		max-width: calc(#{$full-site-width} / 12 * 8);
	}
}

// Bootstrap 4 hiding classes
.hidden-xs-up {
	display: none !important;
}

@media (max-width: 543px) {
	.hidden-xs-down {
		display: none !important;
	}
}

@include bp(md) {
	.hidden-sm-up {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.hidden-sm-down {
		display: none !important;
	}
}

@include bp(md) {
	.hidden-md-up {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.hidden-md-down {
		display: none !important;
	}
}

@include bp(lg) {
	.hidden-lg-up {
		display: none !important;
	}
}

@media (max-width: 1199px) {
	.hidden-lg-down {
		display: none !important;
	}
}

@include bp(xl) {
	.hidden-xl-up {
		display: none !important;
	}
}

.hidden-xl-down {
	display: none !important;
}
