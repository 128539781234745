/*-----------------------------------*\
	Typography
\*-----------------------------------*/

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-size: 14px;
	line-height: 22px;
	font-family: $font-body;
	font-weight: $weight-400;

	color: RGB(var(--complementary));

	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;

	@include bp(lg) {
		font-size: 16px;
		line-height: 24px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	clear: both;
	hyphens: manual;

	font-family: $font-header;
	font-weight: $weight-700;

	// Don't break headlines on mobile
	br {
		display: none;
	}

	@include bp(lg) {
		br {
			display: block;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}

h1, .h1 {
	margin-bottom: 20px;

	font-size: 40px;
	line-height: 47px;

	@include bp(lg) {
		font-size: 54px;
		line-height: 63px;
	}
}

h2, .h2 {
	margin-bottom: 20px;

	font-size: 30px;
	line-height: 35px;

	@include bp(lg) {
		font-size: 38px;
		line-height: 45px;
	}
}

h3, .h3 {
	margin-bottom: 20px;

	font-size: 22px;
	line-height: 26px;

	@include bp(lg) {
		font-size: 28px;
		line-height: 33px;
	}
}

h4, .h4 {
	margin-bottom: 20px;

	font-size: 20px;
	line-height: 23px;

	@include bp(lg) {
		font-size: 22px;
		line-height: 26px;
	}

}

h5, .h5 {
	margin-bottom: 10px;

	font-size: 16px;
	line-height: 19px;

	@include bp(hg) {
		font-size: 18px;
		line-height: 21px;
	}
}

h6, .h6 {
	@extend .h5;
}

p, .aa-text--normal {
	margin-top: 0;
    margin-bottom: 0;

	&:not(:last-of-type) {
		margin-bottom: 20px;

		@include bp(md) {
			margin-bottom: 30px;
		}
	}
}

.aa-text--small {
	font-size: 12px;
	line-height: 18px;

	@include bp(lg) {
		font-size: 14px;
		line-height: 22px;
	}
}

.aa-text--big {
	font-size: 16px;
	line-height: 24px;

	@include bp(lg) {
		font-size: 20px;
		line-height: 30px;
	}
}

p + ul {
	margin-bottom: 30px;
	margin-top: 0;

	@include bp(lg) {
		margin-bottom: 40px;
	}
}

img + p,
img + strong,
img + h1,
img + h2,
img + h3,
img + h4,
img + h5,
img + h6,
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
	margin-top: 30px;
}

blockquote {
	@extend %eiffel-top-margin;
	@extend %eiffel-bottom-margin;

    margin-left: 0; // Browser reset
    margin-right: 0; // Browser reset

	font-style: italic;
	font-size: 28px;
	line-height: 33px;

	@include bp(md) {
		font-size: 31px;
		line-height: 36px;
	}
}

blockquote strong {
	font-style: normal;
	font-weight: $weight-700;
	font-size: 14px;
	line-height: 30px;
	text-decoration: none;

	@include bp(md) {
		font-size: 18px;
		line-height: 30px;
	}
}

strong {
	font-weight: $weight-700;
}

i {
	font-style: italic;
}

figcaption {
	font-family: $font-body;
	font-size: 15px;
	line-height: 21px;
	font-style: italic;
}

.aa-subheading,
.aa-label {
    font-weight: $weight-400;
	font-size: 13px;
	line-height: 15px;
	color: RGB(var(--complementary));

	text-transform: uppercase;
	letter-spacing: 2px;
	display: block;
	margin-bottom: 15px;

	@include bp(lg) {
		margin-bottom: 20px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}